import { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Typography from "@/components/Typography";
import { Skeleton } from "@/components/Loader";
import { useDialog, useTrackers, useLock } from "@/hooks";
import { TopLevelDialogNames } from "@/components/TopLevelDialogs";
import {
  useTripCurrencyInformationQuery,
  useSubmissionTypesListQuery,
  useSubmissionsListQuery,
  useTransactionsQuery,
  useAllClaimablesListQuery,
  ClaimableItemTypes,
} from "@/fetch/rewards";
import { EmptyRewardCards } from "@/components/EmptyList";
import RewardCard, {
  GlobalDollarTransactionCard,
  YoutubeDialog,
  PhotosDialog,
  VideoDialog,
} from ".";
import styles from "./RewardCenterTab.module.scss";

const RewardCenterTab: FC = () => {
  const { openTopLevelDialog } = useDialog();
  const { track } = useTrackers();
  const { isSuperliteTrip } = useLock();

  const { openDialog: openPhotosDialog, isOpen: isPhotosDialogOpen } =
    useDialog("photo");
  const { openDialog: openVideoDialog, isOpen: isVideoDialogOpen } =
    useDialog("videoAd");
  const { openDialog: openYouTubeDialog, isOpen: isYoutubeDialogOpen } =
    useDialog("youtube");

  const { data: submissionData, isLoading: isLoadingSubmissionList } =
    useSubmissionsListQuery();

  const { data: currencyInformation, isLoading: isLoadingCurrency } =
    useTripCurrencyInformationQuery();
  const { data: submissionTypesList, isLoading: isLoadingSubmissionTypes } =
    useSubmissionTypesListQuery();
  const {
    data: transactionData,
    isLoading: isLoadingTransactions,
    fetchNextPage: fetchTransactionsNextPage,
    hasNextPage: hasTransactionsNextPage,
    isFetchingNextPage: isFetchingTransactionsNextPage,
  } = useTransactionsQuery();
  const { isLoading: isLoadingClaimables, data: claimableList } =
    useAllClaimablesListQuery();

  const referralClaimable = claimableList?.result.find(
    (eachClaimable) => eachClaimable.type === ClaimableItemTypes.REFERRAL
  );

  const photoClaimable = claimableList?.result.find(
    (eachClaimable) => eachClaimable.type === ClaimableItemTypes.PHOTO
  );
  const videoClaimable = claimableList?.result.find(
    (eachClaimable) => eachClaimable.type === ClaimableItemTypes.VIDEO_AD
  );
  const youtubeClaimable = claimableList?.result.find(
    (eachClaimable) => eachClaimable.type === ClaimableItemTypes.YOUTUBE
  );

  const isEmpty =
    !referralClaimable &&
    !photoClaimable &&
    !videoClaimable &&
    !youtubeClaimable;

  const {
    ref: transactionsLoaderContainerRef,
    inView: isTransactionsLoaderInView,
  } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (
      !isTransactionsLoaderInView ||
      !hasTransactionsNextPage ||
      isLoadingTransactions ||
      isFetchingTransactionsNextPage
    )
      return;

    fetchTransactionsNextPage();
  }, [
    fetchTransactionsNextPage,
    hasTransactionsNextPage,
    isFetchingTransactionsNextPage,
    isTransactionsLoaderInView,
    isLoadingTransactions,
  ]);

  const submissionList = submissionData?.result || [];

  const youtubeSubmission = submissionList.find(
    (each) => each.type === "5" || each.type === 5
  );
  const photosSubmission = submissionList.find(
    (each) => each.type === "1" || each.type === 1
  );
  const videosSubmission = submissionList.find(
    (each) => each.type === "2" || each.type === 2
  );

  const globalDollarTransactionsList =
    transactionData?.pages.flatMap((eachPage) => eachPage.result || []) || [];
  const totalCountTransactions = transactionData?.pages?.[0].totalCount || 0;

  const isLoading =
    isLoadingSubmissionList ||
    isLoadingSubmissionTypes ||
    isLoadingCurrency ||
    isLoadingClaimables;

  const handleOpenPhotosDialog = () => {
    const hasSubmission = Boolean(photosSubmission);
    openPhotosDialog(
      hasSubmission ? { submissionId: photosSubmission?.id } : {}
    );
    track("Photo Reward Started", {
      eventId: "photo-reward-started",
    });
  };

  const handleOpenVideoDialog = () => {
    const hasSubmission = Boolean(videosSubmission);
    openVideoDialog(
      hasSubmission ? { submissionId: videosSubmission?.id } : {}
    );
    track("Video Reward Started", {
      eventId: "video-reward-started",
    });
  };

  const handleOpenYouTubeDialog = () => {
    const hasSubmission = Boolean(youtubeSubmission);
    openYouTubeDialog(
      hasSubmission ? { submissionId: youtubeSubmission?.id } : {}
    );
    track("Youtube Reward Started", {
      eventId: "youtube-reward-started",
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant="text" width={200} />
          <Skeleton variant="text" className={styles.loading} />
          <Skeleton
            height="167px"
            variant="rectangular"
            className={styles.loading}
          />
          <Skeleton
            height="157px"
            variant="rectangular"
            className={styles.loading}
          />
          <Skeleton
            height="157px"
            variant="rectangular"
            className={styles.loading}
          />
          <Skeleton
            height="157px"
            variant="rectangular"
            className={styles.loading}
          />
        </>
      ) : (
        <>
          {isEmpty ? (
            <EmptyRewardCards />
          ) : (
            <>
              <Typography variant="h5" className={styles.title}>
                Share Your Experience &amp; Get Rewarded!
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                className={styles.description}
              >
                Below are a few ways you can start earning Global Dollars today.
              </Typography>
              {referralClaimable && !isSuperliteTrip && (
                <RewardCard
                  isInvitation
                  onOpen={() =>
                    openTopLevelDialog(TopLevelDialogNames.Invitation)
                  }
                  currency={currencyInformation?.result.currency || ""}
                  currencyName={currencyInformation?.result.currencyName || ""}
                />
              )}
              {photoClaimable && (
                <RewardCard
                  type="photo"
                  onOpen={handleOpenPhotosDialog}
                  currency={currencyInformation?.result.currency || ""}
                  currencyName={currencyInformation?.result.currencyName || ""}
                  submissionType={submissionTypesList?.result.find(
                    (each) => each.id === 1
                  )}
                  submission={photosSubmission}
                />
              )}
              {videoClaimable && (
                <RewardCard
                  type="video"
                  onOpen={handleOpenVideoDialog}
                  currency={currencyInformation?.result.currency || ""}
                  currencyName={currencyInformation?.result.currencyName || ""}
                  submissionType={submissionTypesList?.result.find(
                    (each) => each.id === 2
                  )}
                  submission={videosSubmission}
                />
              )}
              {youtubeClaimable && (
                <RewardCard
                  type="youtube"
                  onOpen={handleOpenYouTubeDialog}
                  currency={currencyInformation?.result.currency || ""}
                  currencyName={currencyInformation?.result.currencyName || ""}
                  submissionType={submissionTypesList?.result.find(
                    (each) => each.id === 5
                  )}
                  submission={youtubeSubmission}
                />
              )}
            </>
          )}
        </>
      )}
      {globalDollarTransactionsList.length > 0 && (
        <>
          <Typography variant="h5" className={styles.transactionTitle}>
            Rewards Feed
          </Typography>
          {globalDollarTransactionsList.map((each) => (
            <GlobalDollarTransactionCard key={each.id} transaction={each} />
          ))}

          {totalCountTransactions > 0 && hasTransactionsNextPage && (
            <div ref={transactionsLoaderContainerRef}>
              {isFetchingTransactionsNextPage && (
                <Skeleton
                  variant="rectangular"
                  className={styles.transactionLoader}
                />
              )}
            </div>
          )}
        </>
      )}
      {isYoutubeDialogOpen && <YoutubeDialog />}
      {isPhotosDialogOpen && <PhotosDialog />}
      {isVideoDialogOpen && <VideoDialog />}
    </>
  );
};

export default RewardCenterTab;
