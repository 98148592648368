import "the-new-css-reset/css/reset.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../theme/global.css";
import type { AppProps } from "next/app";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import Slide from "@mui/material/Slide";
import { StyledEngineProvider } from "@mui/material/styles";
import Layout from "@/components/Layout";
import theme from "../theme/theme";
import MuiComponentsConfig from "../theme/MuiComponentsConfig";
import { FlagsmithProvider } from "flagsmith/react";
import flagsmith from "flagsmith";

import "@draft-js-plugins/emoji/lib/plugin.css";
import "plyr-react/plyr.css";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import duration from "dayjs/plugin/duration";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import Script from "next/script";
import Router from "next/router";
import { ChatProvider } from "@/components/Chat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(localData);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "now",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1 Month",
    MM: "%d Months",
    y: "1y",
    yy: "%dy",
  },
});

const muiTheme = createTheme({
  ...theme,
  components: MuiComponentsConfig,
} as any);

console.log(`Version: ${process.env.NEXT_PUBLIC_VERSION}`);

const onRedirectCallback = (appState: any) => {
  const rudderstack = global.window?.rudderanalytics;
  rudderstack?.track("Successfully Logged In", {
    eventId: "successfully-logged-in",
  });
  const { pathname, search } = appState?.returnTo || {};
  let query;
  if (search) {
    const searchParams = new URLSearchParams(search);
    query = Object.fromEntries(searchParams);
  }
  Router.replace(pathname || "/", { query });
};

function MyApp({ Component, pageProps, router }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const hasLayout = router.pathname !== "/share-with-community";

  useEffect(() => {
    // Temporary poll the flagsmith changes (till the realtime feature release)
    flagsmith.startListening(3000);
    return () => {
      flagsmith.stopListening();
    };
  }, []);

  return (
    <>
      <Script id="rudderstack">
        {`
!function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
e.load("${process.env.NEXT_PUBLIC_RUDDERSTACK_KEY}","https://globalworkozs.dataplane.rudderstack.com"),
e.page()}();
          `}
      </Script>
      {/* <Script id="hotjar">
        {`(function(h,o,t,j,a,r){         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};         h._hjSettings={hjid:391630,hjsv:6};         a=o.getElementsByTagName('head')[0];         r=o.createElement('script');r.async=1;         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;         a.appendChild(r);     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); `}
      </Script> */}
      <Script async src="https://js.stripe.com/v3/buy-button.js"></Script>
      <QueryClientProvider client={queryClient}>
        <FlagsmithProvider
          options={{
            environmentID: process.env.NEXT_PUBLIC_FLAGSMITH || "",
            api: "https://flags.gwat.co/api/v1/",
            cacheFlags: true,
            cacheOptions: { ttl: 1000 },
          }}
          flagsmith={flagsmith}
        >
          <Auth0Provider
            domain="login.globalworkandtravel.com"
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            clientId={process.env.NEXT_PUBLIC_GWORLD_AUTH0_CLIENT_ID || ""}
            onRedirectCallback={onRedirectCallback}
            authorizationParams={{
              scope: "openid email profile",
              audience: "https://api.gwat.co",
              redirect_uri: global.window?.location.origin,
            }}
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={muiTheme}>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  TransitionComponent={Slide}
                  maxSnack={3}
                >
                  {!loading && hasLayout && (
                    <ChatProvider>
                      <Layout>
                        <Component {...pageProps} />
                      </Layout>
                    </ChatProvider>
                  )}
                  {!loading && !hasLayout && <Component {...pageProps} />}
                </SnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
            <ReactQueryDevtools />
          </Auth0Provider>
        </FlagsmithProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
