import { useEffect, useState, useMemo } from "react";
import {
  AcademyIcon,
  BellIcon,
  ChatIcon,
  GlobalIcon,
  MarketplaceIcon,
} from "@/components/Icon";
import { Division, Trip } from "@/fetch/gworld";
import { SocialMobileHeader, SocialSidebar } from "@/components/Social";
import { Route, RouteFeature, SubrouteFeature } from "./useRoutes";
import useDivisionMenuOrder from "./useDivisonMenuOrder";

const getRoutes = ({
  trip,
  hasSocialGroup,
  isPaymentLockEnabled,
  isTripCompleted,
  isAfricanRegion,
  getOrder,
}: {
  trip: Trip | undefined | null;
  hasSocialGroup: boolean;
  isPaymentLockEnabled: boolean;
  isTripCompleted: boolean;
  isAfricanRegion: boolean;
  getOrder: (routeId: string, subRouteId?: string) => undefined | number;
}) => {
  const routes: Route[] = [
    {
      name: "Experience",
      path: "/experience/dashboard",
      features: [
        RouteFeature.Sidebar,
        RouteFeature.MobileTab,
        RouteFeature.Tab,
        RouteFeature.HasMobileTripSelector,
      ],
      icon: GlobalIcon,
      id: "1",
      order: getOrder("1") || 1,
      children: [
        {
          name: "Dashboard",
          path: "/experience/dashboard",
          groupIndex: 0,
          disabled: isPaymentLockEnabled,
          id: "1-1",
          order: getOrder("1", "1-1") || 1,
        },
        {
          name: "Payments",
          path: "/experience/payments",
          groupIndex: 0,
          features: [SubrouteFeature.HideSuggestionBanner],
          id: "1-2",
          order: getOrder("1", "1-2") || 2,
        },
        {
          name: "Documents",
          path: "/experience/documents",
          groupIndex: 0,
          disabled: isPaymentLockEnabled,
          isAvailable: trip?.division !== Division.Superlite,
          id: "1-3",
          order: getOrder("1", "1-3") || 3,
        },
        {
          name: "Resume/CV",
          path: "/experience/resume",
          groupIndex: 0,
          disabled: isPaymentLockEnabled,
          isAvailable:
            trip?.division !== Division.Remote &&
            trip?.division !== Division.Tour,
          id: "1-4",
          order: getOrder("1", "1-4") || 4,
        },
        {
          name: `${
            trip?.division === Division.AuPair
              ? "Au Pair"
              : trip?.division === Division.Tutor
              ? "Tutor"
              : ""
          } Profile`,
          path: "/experience/au-pair-profile",
          groupIndex: 0,
          disabled: isPaymentLockEnabled,
          isAvailable:
            trip?.division === Division.AuPair ||
            trip?.division === Division.Tutor,
          id: "1-5",
          order: getOrder("1", "1-5") || 5,
        },
        {
          name: "Trip Details",
          path: "/experience/trip-details",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          isAvailable: trip?.division !== Division.Superlite,
          id: "1-6",
          order: getOrder("1", "1-6") || 6,
        },
        {
          name: "Flight Details",
          path: "/experience/flight",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          isAvailable: trip?.division !== Division.Superlite,
          id: "1-7",
          order: getOrder("1", "1-7") || 7,
        },
        {
          name: "Jobs",
          path: "/experience/jobs?tab=job-opportunities",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          isAvailable: trip?.division === Division.WorkingHoliday,
          id: "1-8",
          order: getOrder("1", "1-8") || 8,
        },
        {
          name: "Arrival",
          path: "/experience/arrival",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          isAvailable: trip?.division === Division.WorkingHoliday,
          id: "1-9",
          order: getOrder("1", "1-9") || 9,
        },
        {
          name: "Accommodation",
          path: "/experience/accommodation",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          isAvailable: trip?.division === Division.WorkingHoliday,
          id: "1-10",
          order: getOrder("1", "1-10") || 10,
        },
        {
          name: "Host Family",
          path: "/experience/host",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          isAvailable:
            trip?.division === Division.AuPair ||
            trip?.division === Division.Tutor,
          id: "1-11",
          order: getOrder("1", "1-11") || 11,
        },
        {
          name: "Tours",
          path: "/experience/tours",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,

          id: "1-12",
          order: getOrder("1", "1-12") || 12,
        },
        {
          name: "Insurance",
          path: "/experience/insurance",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          id: "1-13",
          order: getOrder("1", "1-13") || 13,
        },
        {
          name: "Visas",
          path: "/experience/visas",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          isAvailable: trip?.destination !== "Remote",
          id: "1-14",
          order: getOrder("1", "1-14") || 14,
        },
        {
          name: "Resources",
          path: "/experience/resources?tab=resources",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          isAvailable:
            trip?.division === Division.Superlite ||
            trip?.division === Division.WorkingHoliday,
          id: "1-15",
          order: getOrder("1", "1-15") || 15,
        },
        {
          name: "Animal welfare",
          path: "/experience/animal-welfare",
          groupIndex: 1,
          disabled: isPaymentLockEnabled,
          id: "1-16",
          order: getOrder("1", "1-16") || 16,
        },
      ],
    },
    {
      name: "Marketplace",
      path: "/marketplace",
      icon: MarketplaceIcon,
      disabled: isTripCompleted,
      features: [RouteFeature.MobileTab, RouteFeature.Tab],
      id: "2",
      order: getOrder("2") || 2,
    },
    {
      name: "Academy",
      path: "/academy",
      icon: AcademyIcon,
      disabled: isTripCompleted,
      features: [RouteFeature.MobileTab, RouteFeature.Tab],
      id: "3",
      order: getOrder("3") || 3,
    },
    {
      name: "Social",
      path: "/social/timeline?tab=feed",
      icon: ChatIcon,
      disabled: !hasSocialGroup,
      tooltipMessage: isAfricanRegion
        ? "Social is yet to launch for this destination."
        : !hasSocialGroup && !isTripCompleted
        ? `Your private social network, unlocked just before your trip starts.`
        : "",
      features: [
        RouteFeature.MobileTab,
        RouteFeature.Tab,
        RouteFeature.Sidebar,
      ],
      customMobileHeader: <SocialMobileHeader />,
      customSidebar: <SocialSidebar />,
      rootClassName: "no-padding",
      isAvailable:
        trip?.division !== Division.Tour && trip?.destination !== "Remote",
      id: "4",
      order: getOrder("4") || 4,
      children: [
        {
          name: "Timeline",
          path: "/social/timeline?tab=feed",
          groupIndex: 0,
          id: "4-1",
          order: getOrder("4", "4-1") || 1,
        },
        {
          name: "Profile",
          path: "/social/profile",
          groupIndex: 0,
          id: "4-2",
          order: getOrder("4", "4-2") || 2,
        },
        {
          name: "Messages",
          path: "/social/messages",
          groupIndex: 0,
          features: [SubrouteFeature.HideChatPopover],
          id: "4-3",
          order: getOrder("4", "4-3") || 3,
        },
      ],
    },
    {
      name: "Notifications",
      path: "/notifications",
      icon: BellIcon,
      features: [RouteFeature.MobileTab, RouteFeature.HasMobileTripSelector],
      id: "5",
      order: getOrder("5") || 5,
    },
    {
      name: "Account",
      path: "/account/general",
      features: [
        RouteFeature.Sidebar,
        RouteFeature.SidebarTitle,
        RouteFeature.HasMobileTripSelector,
      ],
      id: "6",
      order: getOrder("6") || 6,
      children: [
        {
          name: "General",
          path: "/account/general",
          groupIndex: 0,
          id: "6-1",
          order: getOrder("6", "6-1") || 1,
        },
        {
          name: "Contact",
          path: "/account/contact",
          groupIndex: 0,
          isAvailable: trip?.division !== Division.Superlite,
          id: "6-2",
          order: getOrder("6", "6-2") || 2,
        },
        {
          name: "Personal",
          path: "/account/personal",
          groupIndex: 0,
          isAvailable: trip?.division !== Division.Superlite,
          id: "6-3",
          order: getOrder("6", "6-3") || 3,
        },
        {
          name: "Visa",
          path: "/account/visa",
          groupIndex: 0,
          isAvailable: trip?.division === Division.WorkingHoliday,
          id: "6-4",
          order: getOrder("6", "6-4") || 4,
        },
        {
          name: "Settings",
          path: "/account/settings",
          groupIndex: 1,
          id: "6-5",
          order: getOrder("6", "6-5") || 5,
        },
        {
          name: "About",
          path: "/account/about",
          groupIndex: 1,
          id: "6-6",
          order: getOrder("6", "6-6") || 6,
        },
      ],
    },
    {
      name: "Setup",
      path: "/setup",
      features: [RouteFeature.HideSuggestionBanner],
      id: "7",
      order: getOrder("7") || 7,
    },
    {
      name: "Finalise",
      path: "/finalise",
      features: [
        RouteFeature.HideSuggestionBanner,
        RouteFeature.HasMobileTripSelector,
      ],
      id: "8",
      order: getOrder("8") || 8,
    },
    {
      name: "Cancellation",
      path: "/cancellation",
      features: [RouteFeature.HasMobileTripSelector],
      id: "9",
      order: getOrder("9") || 9,
    },
    {
      name: "Lock",
      path: "/lock",
      features: [RouteFeature.HasMobileTripSelector],
      id: "10",
      order: getOrder("10") || 10,
    },
    {
      name: "Rewards",
      path: "/rewards?tab=reward-center",
      features: [RouteFeature.HasMobileTripSelector],
      id: "11",
      order: getOrder("11") || 11,
    },
  ];

  return routes
    .filter((route) => {
      if (route.isAvailable === undefined) return true;
      return route.isAvailable;
    })
    .map((route) => {
      if (route.children)
        route.children = route.children.filter((subroute) => {
          if (subroute.isAvailable === undefined) return true;

          return subroute.isAvailable;
        });

      return route;
    });
};

const useOrderedMenuByTripDivision = ({
  trip,
  hasSocialGroup,
  isPaymentLockEnabled,
  isTripCompleted,
  isAfricanRegion,
}: {
  trip: Trip | undefined | null;
  hasSocialGroup: boolean;
  isPaymentLockEnabled: boolean;
  isTripCompleted: boolean;
  isAfricanRegion: boolean;
}) => {
  const [sortedRoutes, setSortedRoutes] = useState<Array<Route>>([]);
  const { getOrder } = useDivisionMenuOrder(trip);

  const routes: Array<Route> = getRoutes({
    trip,
    hasSocialGroup,
    isPaymentLockEnabled,
    isTripCompleted,
    isAfricanRegion,
    getOrder,
  });

  const currentTrip = useMemo(() => trip, [trip?.id]);
  const currentSocialStatus = useMemo(() => hasSocialGroup, [hasSocialGroup]);
  const currentPaymentLock = useMemo(
    () => isPaymentLockEnabled,
    [isPaymentLockEnabled]
  );
  const currentTripCompletedStatus = useMemo(
    () => isTripCompleted,
    [isTripCompleted]
  );
  const currentAfricanRegionStatus = useMemo(
    () => isAfricanRegion,
    [isAfricanRegion]
  );

  useEffect(() => {
    const currentSortedRoutes = routes
      .map((eachRoute) => {
        if (eachRoute.children) {
          const sortedChildren = [...eachRoute.children].sort(
            (firstChild, secondChild) =>
              (firstChild.order || 1) - (secondChild.order || 0)
          );
          return { ...eachRoute, children: sortedChildren };
        }
        return eachRoute;
      })
      .sort(
        (firstRoute, secondRoute) =>
          (firstRoute.order || 1) - (secondRoute.order || 0)
      );
    setSortedRoutes(currentSortedRoutes);
  }, [
    currentTrip,
    currentSocialStatus,
    currentPaymentLock,
    currentTripCompletedStatus,
    currentAfricanRegionStatus,
  ]);

  return sortedRoutes;
};

export default useOrderedMenuByTripDivision;
